import React from 'react'
import { CircularProgress, withStyles, Button, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { UiContext } from '@context'
import { withRouter } from 'react-router'

const styles = () => ({
  links: {
    '& a': {
      color: '#505050',
      margin: '0px 15px',
      '&:hover': {
        color: '#303FA0',
        textDecoration: 'none'
      }
    }
  },
  button: {
    width: '80px',
    display: 'inline-block',
    padding: '7px 15px'
  },
  updateButton: {
    marginRight: '10px',
    border: '2px solid #F5F5F5',
    background: 'linear-gradient(#FFF, #FAFAFA)',
    textTransform: 'none'
  },
  logoutButton: {
    backgroundColor: '#303FA0',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#303FA0'
    }
  }
})

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      env: JSON.parse(localStorage.getItem('env'))
    }
  }
  static contextType = UiContext

  render() {
    const { location, classes } = this.props
    return (
      <Grid
        container
        justify="space-between"
        style={{
          borderBottom: 'solid 1px #F5F5F5'
        }}
      >
        <Grid item style={{ padding: '25px 10px' }}>
          {location.pathname.includes('ap1/prod/instagram/') ? (
            <Grid className={classes.links}>
              <Link to="/ap1/prod/instagram/batch">Batch</Link>
              <Link to="/ap1/prod/instagram/batch/errors">Batch Errors</Link>
              <Link to="/ap1/prod/instagram/profile">Profile</Link>
              <Link to="/ap1/prod/instagram/profile/errors">
                Profile Errors
              </Link>
              <Link to="/ap1/prod/instagram/post">Post</Link>
              <Link to="/ap1/prod/instagram/post/errors">Post Errors</Link>
            </Grid>
          ) : (
            ''
          )}
          {location.pathname.includes('ap1/dev/instagram/') ? (
            <Grid className={classes.links}>
              <Link to="/ap1/dev/instagram/batch">Batch</Link>
              <Link to="/ap1/dev/instagram/batch/errors">Batch Errors</Link>
              <Link to="/ap1/dev/instagram/profile">Profile</Link>
              <Link to="/ap1/dev/instagram/profile/errors">Profile Errors</Link>
              <Link to="/ap1/dev/instagram/post">Post</Link>
              <Link to="/ap1/dev/instagram/post/errors">Post Errors</Link>
            </Grid>
          ) : (
            ''
          )}
          {location.pathname.includes('ap2/prod/instagram/') ? (
            <Grid className={classes.links}>
              <Link to="/ap2/prod/instagram/batch">Batch</Link>
              <Link to="/ap2/prod/instagram/batch/errors">Batch Errors</Link>
              <Link to="/ap2/prod/instagram/profile">Profile</Link>
              <Link to="/ap2/prod/instagram/profile/errors">
                Profile Errors
              </Link>
              <Link to="/ap2/prod/instagram/post">Post</Link>
              <Link to="/ap2/prod/instagram/post/errors">Post Errors</Link>
            </Grid>
          ) : (
            ''
          )}
          {location.pathname.includes('ap2/stg/instagram/') ? (
            <Grid className={classes.links}>
              <Link to="/ap2/stg/instagram/batch">Batch</Link>
              <Link to="/ap2/stg/instagram/batch/errors">Batch Errors</Link>
              <Link to="/ap2/stg/instagram/profile">Profile</Link>
              <Link to="/ap2/stg/instagram/profile/errors">Profile Errors</Link>
              <Link to="/ap2/stg/instagram/post">Post</Link>
              <Link to="/ap2/stg/instagram/post/errors">Post Errors</Link>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Grid item style={{ padding: '14px 20px' }}>
          {location.pathname.includes('/prod/') ||
          location.pathname.includes('/stg/') ||
          location.pathname.includes('/dev/') ? (
            !this.context.updateUrl ? (
              <Button
                className={`${classes.updateButton} ${classes.button}`}
                onClick={() => {
                  this.context.setUpdateUrl(location.pathname)
                  window.location.reload()
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                className={`${classes.updateButton} ${classes.button}`}
                disabled
              >
                <CircularProgress style={{ zoom: 0.5 }} />
              </Button>
            )
          ) : (
            ''
          )}

          <Link to={`/login/${this.state.env}`} style={{ color: 'white' }}>
            <Button className={`${classes.logoutButton} ${classes.button}`}>
              Logout
            </Button>
          </Link>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(withRouter(Header))
