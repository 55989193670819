import React from 'react'
import Table from '../../../templates/batch'
import { getAP1DevInstagramUsers } from '@utils'
import { processInstagramBatch } from '@utils'
import { UiContext } from '@context'

class InstagramBatch extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.devAP1IgUsersBatch.length < 1 ||
      this.context.updateUrl === '/ap1/dev/instagram/batch'
    ) {
      try {
        const users = await getAP1DevInstagramUsers()
        const data = await processInstagramBatch(users)
        this.context.setDevAP1IgUsersBatch(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.devAP1IgUsersBatch}
        title="AP1 Develop Instagram Batch"
        fileName="ap1_prod_ig_batch"
      />
    )
  }
}

export default InstagramBatch
