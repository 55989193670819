import React from 'react'
import Table from '../../../templates/batch'
import { getAP1ProdInstagramUsers } from '@utils'
import { processInstagramBatch } from '@utils'
import { UiContext } from '@context'

class InstagramBatch extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.prodAP1IgUsersBatch.length < 1 ||
      this.context.updateUrl === '/ap1/prod/instagram/batch'
    ) {
      try {
        const users = await getAP1ProdInstagramUsers()
        const data = await processInstagramBatch(users)
        this.context.setProdAP1IgUsersBatch(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodAP1IgUsersBatch}
        title="AP1 Production Instagram Batch"
        fileName="ap1_prod_ig_batch"
      />
    )
  }
}

export default InstagramBatch
