import React from 'react'
import Table from '../../../templates/profile'
import { getAP1DevInstagramUsers } from '@utils'
import { processInstagramProfile } from '@utils'
import { UiContext } from '@context'

class InstagramProfile extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.devAP1IgUsersProf.length < 1 ||
      this.context.updateUrl === '/ap1/dev/instagram/profile'
    ) {
      try {
        const users = await getAP1DevInstagramUsers()
        const data = await processInstagramProfile(users)
        this.context.setDevAP1IgUsersProf(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.devAP1IgUsersProf}
        title="AP1 Develop Instagram Profile"
        fileName="ap1_prod_ig_prof"
      />
    )
  }
}

export default InstagramProfile
