import React from 'react'
import Table from '../../../templates/errors'
import { getProdInstagramUsers, processInstagramBatchErrorsAp2 } from '@utils'
import { UiContext } from '@context'

class InstagramBatchErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('prodToken'))

    if (
      this.context.prodIgUsersBatchErr.length < 1 ||
      this.context.updateUrl === '/ap2/prod/instagram/batch/errors'
    ) {
      try {
        const users = await getProdInstagramUsers(token)
        const data = processInstagramBatchErrorsAp2(users)
        this.context.setProdIgUsersBatchErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodIgUsersBatchErr}
        title="AP2 Production Instagram Batch Errors"
        fileName="ap2_prod_ig_batch_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramBatchErrors
