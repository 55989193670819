import React from 'react'
import Table from '../../../templates/profile'
import { getStgInstagramUsers } from '@utils'
import { processInstagramProfile } from '@utils'
import { UiContext } from '@context'

class InstagramProfile extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('stgToken'))
    if (
      this.context.stgIgUsersProf.length < 1 ||
      this.context.updateUrl === '/ap2/stg/instagram/profile'
    ) {
      try {
        const users = await getStgInstagramUsers(token)
        const data = await processInstagramProfile(users)
        this.context.setStgIgUsersProf(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.stgIgUsersProf}
        title="AP2 Staging Instagram Profile"
        fileName="ap2_stg_ig_prof"
      />
    )
  }
}

export default InstagramProfile
