import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import moment from 'moment'

class PrivateRouteWrapper extends Component {
  constructor() {
    super()
    this.state = {
      env: JSON.parse(localStorage.getItem('env')),
      tokens: {
        prod: JSON.parse(localStorage.getItem('prodToken')),
        stg: JSON.parse(localStorage.getItem('stgToken'))
      }
    }
  }
  componentDidMount() {
    const { tokens, env } = this.state
    const decoded = jwt.decode(tokens[this.state.env])

    // Expires in 1 day after login
    if (decoded) {
      const expiryDate = moment(new Date(decoded.exp))
      if (moment().isAfter(expiryDate)) {
        window.location.pathname = `/login/${env}`
      }
    } else {
      window.location.pathname = `/login/${env}`
    }
  }

  render() {
    const { children } = this.props
    const { tokens, env } = this.state

    if (tokens[env]) {
      return <Route>{children}</Route>
    } else {
      return (
        <Redirect
          to={{
            pathname: `/login/${env}`
          }}
        />
      )
    }
  }
}

export default PrivateRouteWrapper
