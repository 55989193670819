import * as React from 'react'
import * as images from '@assets'
import { Link } from 'react-router-dom'
import { withStyles, Grid } from '@material-ui/core'
import { UiContext } from '@context'
import { withRouter } from 'react-router'

const styles = () => ({
  links: {
    '& a': {
      color: '#505050',
      display: 'block',
      margin: '20px 0px',
      '&:hover': {
        color: '#303FA0',
        textDecoration: 'none'
      }
    }
  },
  active: {
    borderBottom: '2px solid #303FA0',
    cursor: 'pointer'
  },
  header: {
    margin: '20px 0px'
  }
})

class Navigation extends React.Component {
  constructor() {
    super()
    this.state = {
      env: JSON.parse(localStorage.getItem('env'))
    }
  }

  static contextType = UiContext
  render() {
    function renderProdNav() {
      return (
        <Grid style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center', padding: '30px 0px' }}>
            <h3 className={classes.header}>Tracker</h3>
            <Link to="/">
              <img src={images.radar} alt="logo" />
            </Link>
          </div>
          <div className={classes.links}>
            <h5 className={classes.header}>AP1</h5>
            <Link to="/ap1/prod/instagram/batch">Instagram Production</Link>
            <h5 className={classes.header} style={{ marginTop: '30px' }}>
              AP2
            </h5>
            <Link to="/ap2/prod/instagram/batch">Instagram Production</Link>
          </div>
        </Grid>
      )
    }

    function renderStgNav() {
      return (
        <Grid style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center', padding: '30px 0px' }}>
            <h3 className={classes.header}>Tracker</h3>
            <Link to="/">
              <img src={images.radar} alt="logo" />
            </Link>
          </div>
          <div className={classes.links}>
            <h5 className={classes.header}>AP1</h5>
            <Link to="/ap1/dev/instagram/batch">Instagram Develop</Link>
            <h5 className={classes.header} style={{ marginTop: '30px' }}>
              AP2
            </h5>
            <Link to="/ap2/stg/instagram/batch">Instagram Staging</Link>
          </div>
        </Grid>
      )
    }

    const { classes } = this.props

    if (this.state.env === 'prod') {
      return renderProdNav()
    }
    if (this.state.env === 'stg') {
      return renderStgNav()
    }
  }
}

export default withStyles(styles)(withRouter(Navigation))
