import axios from 'axios'

export const getStgToken = async (user) => {
  const token = await axios.post(
    'https://stg-ac-client-api.ambassadors.jp/basic/auth',
    user
  )
  return JSON.parse(token.request.response).data.token
}

export const getProdToken = async (user) => {
  const token = await axios.post(
    'https://ac-client-api.ambassadors.jp/basic/auth',
    user
  )
  return JSON.parse(token.request.response).data.token
}

const getStgInfo = async (url, token) => {

  let records = [];

  try {
    let res = null;
    let offset = 0;
    do {
      const newUrl = `${url}?offset=${offset}`;
      res = await axios.get(newUrl, {
        headers: {
          Authorization: token,
          'x-api-key': process.env.REACT_APP_API_KEY_STG
        }
      });
      records = records.concat(res.data.data);
      offset = res.data.next_offset;

    } while (res.data.next_offset !== 0);

  } catch (error) {
    console.error(error);
  } finally {
    return records;
  }
}

const getStgPostInfo = async (url, token) => {
  let records = [];
  try {
    let res = null;
    let offset = 0;
    do {
      const newUrl = `${url}?offset=${offset}`;
      res = await axios.get(newUrl, {
        headers: {
          Authorization: token,
          'x-api-key': process.env.REACT_APP_API_KEY_STG
        }
      });
      records = records.concat(res.data.data);
      offset += 1000;
    } while (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0);
    return records;
  } catch (error) {
    console.error(error);
  } finally {
    return records;
  }
  
} 

const getProdInfo = async (url, token) => {
  let records = [];

  try {
    let res = null;
    let offset = 0;
    do {
      const newUrl = `${url}?offset=${offset}`;
      res = await axios.get(newUrl, {
        headers: {
          Authorization: token,
          'x-api-key': process.env.REACT_APP_API_KEY_PROD
        }
      });
      records = records.concat(res.data.data);
      offset = res.data.next_offset;

    } while (res.data.next_offset !== 0);

  } catch (error) {
    console.error(error);
  } finally {
    return records;
  }
}

const getProdPostInfo = async (url, token) => {  
  let records = [];
  try {
    let res = null;
    let offset = 0;
    do {
      const newUrl = `${url}?offset=${offset}`;
      res = await axios.get(newUrl, {
        headers: {
          Authorization: token,
          'x-api-key': process.env.REACT_APP_API_KEY_PROD
        }
      });
      records = records.concat(res.data.data);
      offset += 1000;
    } while (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0);
    return records;
  } catch (error) {
    console.error(error);
  } finally {
    return records;
  }
  
} 

export const getStgInstagramUsers = async (token) => {
  return getStgInfo(
    'https://stg-ac-client-api.ambassadors.jp/voice/programs/all/instagramUsers',
    token
  )
}

export const getProdInstagramUsers = async (token) => {
  return getProdInfo(
    'https://ac-client-api.ambassadors.jp/voice/programs/all/instagramUsers',
    token
  )
}

export const getStgInstagramPosts = async (token) => {
  return getStgPostInfo(
    'https://stg-ac-client-api.ambassadors.jp/voice/programs/all/instagramPosts',
    token
  )
}

export const getProdInstagramPosts = async (token) => {
  return getProdPostInfo(
    'https://ac-client-api.ambassadors.jp/voice/programs/all/instagramPosts',
    token
  )
}

export const getAP1DevInstagramUsers = async () => {
  const res = await axios.get(
    'https://tracker-api.ambassador.jp/dev-instagram-users'
  )
  return res.data
}

export const getAP1ProdInstagramUsers = async () => {
  const res = await axios.get(
    'https://tracker-api.ambassador.jp/prod-instagram-users'
  )
  return res.data
}

export const getAP1DevInstagramPosts = async () => {
  const res = await axios.get(
    'https://tracker-api.ambassador.jp/dev-instagram-posts'
  )
  return res.data
}

export const getAP1ProdInstagramPosts = async () => {
  const res = await axios.get(
    'https://tracker-api.ambassador.jp/prod-instagram-posts'
  )
  return res.data
}
