import React from 'react'
import Table from '../../../templates/errors'
import { getStgInstagramUsers } from '@utils'
import { processInstagramProfileErrors } from '@utils'
import { UiContext } from '@context'

class InstagramProfileErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('stgToken'))
    if (
      this.context.stgIgUsersProfErr.length < 1 ||
      this.context.updateUrl === '/ap2/stg/instagram/profile/errors'
    ) {
      try {
        const users = await getStgInstagramUsers(token)
        const data = await processInstagramProfileErrors(users)
        this.context.setStgIgUsersProfErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.stgIgUsersProfErr}
        title="AP2 Staging Instagram Profile Errors"
        fileName="ap2_stg_ig_prof_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramProfileErrors
