import React from 'react'
import Table from '../../../templates/profile'
import { getAP1ProdInstagramUsers } from '@utils'
import { processInstagramProfile } from '@utils'
import { UiContext } from '@context'

class InstagramProfile extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.prodAP1IgUsersProf.length < 1 ||
      this.context.updateUrl === '/ap1/prod/instagram/profile'
    ) {
      try {
        const users = await getAP1ProdInstagramUsers()
        const data = await processInstagramProfile(users)
        this.context.setProdAP1IgUsersProf(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodAP1IgUsersProf}
        title="AP1 Production Instagram Profile"
        fileName="ap1_prod_ig_prof"
      />
    )
  }
}

export default InstagramProfile
