import React from 'react'
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core'
import { Face, Fingerprint } from '@material-ui/icons'
import { UiContext } from '@context'
import { getStgToken, getProdToken } from '@utils'

const styles = (theme) => ({
  padding: {
    padding: theme.spacing.unit,
    width: '800px',
    marginTop: '10%',
    margin: '0 auto'
  },
  outerDiv: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    textAlign: 'center',
    background: '#F8F8F8'
  },
  '@media screen and (max-width: 400px)': {
    padding: {
      marginTop: '0px',
      maxWidth: '100%'
    },
    input: {
      minWidth: '85%'
    }
  }
})

const envText = {
  prod: 'Production',
  stg: 'Staging'
}

class Login extends React.Component {
  static contextType = UiContext
  state = {
    email: '',
    password: '',
    loginError: '',
    loading: false
  }
  handleLoginInfo(e) {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  componentDidMount() {
    localStorage.clear()
    this.context.setEnv(this.props.match.params.env)
    this.setState({
      env: this.props.match.params.env
    })
  }

  async login() {
    const { email, password, env } = this.state
    const { history } = this.props
    if (email.includes('@agilemedia.jp') && password) {
      this.setState({
        loginError: '',
        loading: true
      })

      let token = ''
      try {
        if (env === 'stg') {
          token = await getStgToken({ email, password })
          this.context.setStgToken(token)
        }
        if (env === 'prod') {
          token = await getProdToken({ email, password })
          this.context.setProdToken(token)
        }
      } catch (error) {
        console.error(error)
        token = ''
      }
      if (token) {
        history.push('/')
      } else {
        this.setState({
          loginError: 'メールアドレスもしくはパスワードが正しくありません。'
        })
      }
      this.setState({
        loading: false
      })
    } else if (!email || !password) {
      this.setState({
        loginError: 'メールアドレスとパスワードを入力してください。'
      })
    } else {
      this.setState({
        loginError: '@agilemedia.jpのメールアドレスでログインしてください。'
      })
    }
  }

  render() {
    const { loading, loginError, env } = this.state
    const { classes } = this.props
    return (
      <Grid className={classes.outerDiv}>
        <Paper className={classes.padding}>
          <span style={{ padding: '15px', fontSize: '30px' }}>
            {envText[env]}
          </span>
          <Grid container style={{ padding: '15px' }}>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item lg className={classes.input}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  autoFocus
                  required
                  onChange={(e) => this.handleLoginInfo(e)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Fingerprint />
              </Grid>
              <Grid item lg className={classes.input}>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  onChange={(e) => this.handleLoginInfo(e)}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: '20px' }}>
              <Grid item xs style={{ color: 'red' }}>
                {loginError}
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ marginTop: '20px', marginBottom: '15px' }}
            >
              {!loading ? (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  onClick={() => this.login()}
                >
                  Login
                </Button>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

export default withStyles(styles)(Login)
