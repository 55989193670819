import React from 'react'
import { UiContext } from '@context'
import { useLocalStorage } from '@utils'

const GlobalStateWrapper = ({ children }) => {
  const [env, setEnv] = useLocalStorage('env', '')
  const [stgToken, setStgToken] = useLocalStorage('stgToken', '')
  const [prodToken, setProdToken] = useLocalStorage('prodToken', '')
  const [updateUrl, setUpdateUrl] = useLocalStorage('updateUrl', '')
  const [stgIgUsersBatch, setStgIgUsersBatch] = useLocalStorage(
    'stgIgUsersBatch',
    []
  )
  const [stgIgUsersBatchErr, setStgIgUsersBatchErr] = useLocalStorage(
    'stgIgUsersBatchErr',
    []
  )
  const [stgIgUsersProf, setStgIgUsersProf] = useLocalStorage(
    'stgIgUsersProf',
    []
  )
  const [stgIgUsersProfErr, setStgIgUsersProfErr] = useLocalStorage(
    'stgIgUsersProfErr',
    []
  )
  const [stgIgUsersPost, setStgIgUsersPost] = useLocalStorage(
    'stgIgUsersPost',
    []
  )
  const [stgIgUsersPostErr, setStgIgUsersPostErr] = useLocalStorage(
    'stgIgUsersPostErr',
    []
  )
  const [prodIgUsersBatch, setProdIgUsersBatch] = useLocalStorage(
    'prodIgUsersBatch',
    []
  )
  const [prodIgUsersBatchErr, setProdIgUsersBatchErr] = useLocalStorage(
    'prodIgUsersBatchErr',
    []
  )
  const [prodIgUsersProf, setProdIgUsersProf] = useLocalStorage(
    'prodIgUsersProf',
    []
  )
  const [prodIgUsersProfErr, setProdIgUsersProfErr] = useLocalStorage(
    'prodIgUsersProfErr',
    []
  )
  const [prodIgUsersPost, setProdIgUsersPost] = useLocalStorage(
    'prodIgUsersPost',
    []
  )
  const [prodIgUsersPostErr, setProdIgUsersPostErr] = useLocalStorage(
    'prodIgUsersPostErr',
    []
  )

  const [devAP1IgUsersBatch, setDevAP1IgUsersBatch] = useLocalStorage(
    'devAP1IgUsersBatch',
    []
  )
  const [devAP1IgUsersBatchErr, setDevAP1IgUsersBatchErr] = useLocalStorage(
    'devAP1IgUsersBatchErr',
    []
  )
  const [devAP1IgUsersProf, setDevAP1IgUsersProf] = useLocalStorage(
    'devAP1IgUsersProf',
    []
  )
  const [devAP1IgUsersProfErr, setDevAP1IgUsersProfErr] = useLocalStorage(
    'devAP1IgUsersProfErr',
    []
  )
  const [devAP1IgUsersPost, setDevAP1IgUsersPost] = useLocalStorage(
    'devAP1IgUsersPost',
    []
  )
  const [devAP1IgUsersPostErr, setDevAP1IgUsersPostErr] = useLocalStorage(
    'devAP1IgUsersPostErr',
    []
  )
  const [prodAP1IgUsersBatch, setProdAP1IgUsersBatch] = useLocalStorage(
    'prodAP1IgUsersBatch',
    []
  )
  const [prodAP1IgUsersBatchErr, setProdAP1IgUsersBatchErr] = useLocalStorage(
    'prodAP1IgUsersBatchErr',
    []
  )
  const [prodAP1IgUsersProf, setProdAP1IgUsersProf] = useLocalStorage(
    'prodAP1IgUsersProf',
    []
  )
  const [prodAP1IgUsersProfErr, setProdAP1IgUsersProfErr] = useLocalStorage(
    'prodAP1IgUsersProfErr',
    []
  )
  const [prodAP1IgUsersPost, setProdAP1IgUsersPost] = useLocalStorage(
    'prodAP1IgUsersPost',
    []
  )
  const [prodAP1IgUsersPostErr, setProdAP1IgUsersPostErr] = useLocalStorage(
    'prodAP1IgUsersPostErr',
    []
  )

  return (
    <UiContext.Provider
      value={{
        env,
        setEnv,
        stgToken,
        setStgToken,
        prodToken,
        setProdToken,
        updateUrl,
        setUpdateUrl,
        stgIgUsersBatch,
        setStgIgUsersBatch,
        stgIgUsersBatchErr,
        setStgIgUsersBatchErr,
        stgIgUsersProf,
        setStgIgUsersProf,
        stgIgUsersProfErr,
        setStgIgUsersProfErr,
        stgIgUsersPost,
        setStgIgUsersPost,
        stgIgUsersPostErr,
        setStgIgUsersPostErr,
        prodIgUsersBatch,
        setProdIgUsersBatch,
        prodIgUsersBatchErr,
        setProdIgUsersBatchErr,
        prodIgUsersProf,
        setProdIgUsersProf,
        prodIgUsersProfErr,
        setProdIgUsersProfErr,
        prodIgUsersPost,
        setProdIgUsersPost,
        prodIgUsersPostErr,
        setProdIgUsersPostErr,
        devAP1IgUsersBatch,
        setDevAP1IgUsersBatch,
        devAP1IgUsersBatchErr,
        setDevAP1IgUsersBatchErr,
        devAP1IgUsersProf,
        setDevAP1IgUsersProf,
        devAP1IgUsersProfErr,
        setDevAP1IgUsersProfErr,
        devAP1IgUsersPost,
        setDevAP1IgUsersPost,
        devAP1IgUsersPostErr,
        setDevAP1IgUsersPostErr,
        prodAP1IgUsersBatch,
        setProdAP1IgUsersBatch,
        prodAP1IgUsersBatchErr,
        setProdAP1IgUsersBatchErr,
        prodAP1IgUsersProf,
        setProdAP1IgUsersProf,
        prodAP1IgUsersProfErr,
        setProdAP1IgUsersProfErr,
        prodAP1IgUsersPost,
        setProdAP1IgUsersPost,
        prodAP1IgUsersPostErr,
        setProdAP1IgUsersPostErr
      }}
    >
      {children}
    </UiContext.Provider>
  )
}

export default GlobalStateWrapper
