import React from 'react'
import Table from '../../../templates/postErrors'
import { getAP1ProdInstagramPosts } from '@utils'
import { processInstagramPostErrors } from '@utils'
import { UiContext } from '@context'

class InstagramPostErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.prodAP1IgUsersPostErr.length < 1 ||
      this.context.updateUrl === '/ap1/prod/instagram/post/errors'
    ) {
      try {
        const posts = await getAP1ProdInstagramPosts()
        const data = await processInstagramPostErrors(posts)
        this.context.setProdAP1IgUsersPostErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodAP1IgUsersPostErr}
        title="AP1 Production Instagram Post Errors"
        fileName="ap1_prod_ig_post_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramPostErrors
