import React from 'react'
import { withStyles } from '@material-ui/core'
import { UiContext } from '@context'

const styles = () => ({
  wrapper: {
    textAlign: 'center',
    margin: '50px',
    marginTop: '200px',
  },
  links: {
    marginTop: '20px',
    '& h2': { color: 'black', marginBottom: '7px' },
    '& a': {
      color: '#303FA0',
      margin: '30px 10px',
      '&:hover': {
        color: '#303FA0',
        textDecoration: 'none',
        fontWeight: '500',
      },
    },
  },
})

class Home extends React.Component {
  static contextType = UiContext

  render() {
    const { classes } = this.props

    return (
      <div className={classes.wrapper}>
        <h1>Trackerへようこそ</h1>
      </div>
    )
  }
}

export default withStyles(styles)(Home)
