// Libraries
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Grid, withStyles } from '@material-ui/core'

import AP1DevInstagramBatch from './pages/ap1/develop/InstagramBatch'
import AP1DevInstagramProfile from './pages/ap1/develop/InstagramProfile'
import AP1DevInstagramBatchErrors from './pages/ap1/develop/InstagramBatchErrors'
import AP1DevInstagramProfileErrors from './pages/ap1/develop/InstagramProfileErrors'
import AP1DevInstagramPost from './pages/ap1/develop/InstagramPost'
import AP1DevInstagramPostErrors from './pages/ap1/develop/InstagramPostErrors'
import AP1ProdInstagramBatch from './pages/ap1/production/InstagramBatch'
import AP1ProdInstagramProfile from './pages/ap1/production/InstagramProfile'
import AP1ProdInstagramBatchErrors from './pages/ap1/production/InstagramBatchErrors'
import AP1ProdInstagramProfileErrors from './pages/ap1/production/InstagramProfileErrors'
import AP1ProdInstagramPost from './pages/ap1/production/InstagramPost'
import AP1ProdInstagramPostErrors from './pages/ap1/production/InstagramPostErrors'
import AP2ProdInstagramBatch from './pages/ap2/production/InstagramBatch'
import AP2ProdInstagramProfile from './pages/ap2/production/InstagramProfile'
import AP2ProdInstagramBatchErrors from './pages/ap2/production/InstagramBatchErrors'
import AP2ProdInstagramProfileErrors from './pages/ap2/production/InstagramProfileErrors'
import AP2ProdInstagramPost from './pages/ap2/production/InstagramPost'
import AP2ProdInstagramPostErrors from './pages/ap2/production/InstagramPostErrors'
import AP2StgInstagramBatch from './pages/ap2/staging/InstagramBatch'
import AP2StgInstagramProfile from './pages/ap2/staging/InstagramProfile'
import AP2StgInstagramBatchErrors from './pages/ap2/staging/InstagramBatchErrors'
import AP2StgInstagramProfileErrors from './pages/ap2/staging/InstagramProfileErrors'
import AP2StgInstagramPost from './pages/ap2/staging/InstagramPost'
import AP2StgInstagramPostErrors from './pages/ap2/staging/InstagramPostErrors'
import Home from './pages/home/Home'
import Header from './components/Header'
import Navigation from './components/Navigation'

const styles = () => ({
  root: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    overflow: 'hidden',
  },
  navigation: {
    position: 'fixed',
    minWidth: '18%',
    background: 'white',
    height: '100%',
    borderRight: 'solid 1px #F5F5F5',
  },
  main: {
    position: 'fixed',
    marginLeft: '18%',
    minWidth: '82%',
    height: '100%',
    background: '#F8F8F8',
    overflow: 'auto',
  },
  header: {
    position: 'fixed',
    zIndex: '1000',
    background: 'white',
    minWidth: '82%',
  },
  table: {
    margin: '85px 15px 65px 15px',
  },
})

class Main extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Grid container className={classes.root}>
        <Grid item sm={2} className={classes.navigation}>
          <Navigation />
        </Grid>
        <Grid item sm={10} className={classes.main}>
          <Grid className={classes.header}>
            <Header />
          </Grid>
          <Grid className={classes.table}>
            <Switch>
              <Route component={Home} exact path="/" strict />
              <Route
                component={AP1DevInstagramBatch}
                exact
                path="/ap1/dev/instagram/batch"
                strict
              />
              <Route
                component={AP1DevInstagramBatchErrors}
                exact
                path="/ap1/dev/instagram/batch/errors"
                strict
              />
              <Route
                component={AP1DevInstagramProfile}
                exact
                path="/ap1/dev/instagram/profile"
                strict
              />
              <Route
                component={AP1DevInstagramProfileErrors}
                exact
                path="/ap1/dev/instagram/profile/errors"
                strict
              />
              <Route
                component={AP1DevInstagramPost}
                exact
                path="/ap1/dev/instagram/post"
                strict
              />
              <Route
                component={AP1DevInstagramPostErrors}
                exact
                path="/ap1/dev/instagram/post/errors"
                strict
              />
              <Route
                component={AP1ProdInstagramBatch}
                exact
                path="/ap1/prod/instagram/batch"
                strict
              />
              <Route
                component={AP1ProdInstagramBatchErrors}
                exact
                path="/ap1/prod/instagram/batch/errors"
                strict
              />
              <Route
                component={AP1ProdInstagramProfile}
                exact
                path="/ap1/prod/instagram/profile"
                strict
              />
              <Route
                component={AP1ProdInstagramProfileErrors}
                exact
                path="/ap1/prod/instagram/profile/errors"
                strict
              />
              <Route
                component={AP1ProdInstagramPost}
                exact
                path="/ap1/prod/instagram/post"
                strict
              />
              <Route
                component={AP1ProdInstagramPostErrors}
                exact
                path="/ap1/prod/instagram/post/errors"
                strict
              />
              <Route
                component={AP2ProdInstagramBatch}
                exact
                path="/ap2/prod/instagram/batch"
                strict
              />
              <Route
                component={AP2ProdInstagramProfile}
                exact
                path="/ap2/prod/instagram/profile"
                strict
              />
              <Route
                component={AP2ProdInstagramBatchErrors}
                exact
                path="/ap2/prod/instagram/batch/errors"
                strict
              />
              <Route
                component={AP2ProdInstagramProfileErrors}
                exact
                path="/ap2/prod/instagram/profile/errors"
                strict
              />
              <Route
                component={AP2ProdInstagramPost}
                exact
                path="/ap2/prod/instagram/post"
                strict
              />
              <Route
                component={AP2ProdInstagramPostErrors}
                exact
                path="/ap2/prod/instagram/post/errors"
                strict
              />
              <Route
                component={AP2StgInstagramBatch}
                exact
                path="/ap2/stg/instagram/batch"
                strict
              />
              <Route
                component={AP2StgInstagramProfile}
                exact
                path="/ap2/stg/instagram/profile"
                strict
              />
              <Route
                component={AP2StgInstagramBatchErrors}
                exact
                path="/ap2/stg/instagram/batch/errors"
                strict
              />
              <Route
                component={AP2StgInstagramProfileErrors}
                exact
                path="/ap2/stg/instagram/profile/errors"
                strict
              />
              <Route
                component={AP2StgInstagramPost}
                exact
                path="/ap2/stg/instagram/post"
                strict
              />
              <Route
                component={AP2StgInstagramPostErrors}
                exact
                path="/ap2/stg/instagram/post/errors"
                strict
              />
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Main)
