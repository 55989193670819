import React from 'react'
import Table from '../../../templates/post'
import { getStgInstagramPosts } from '@utils'
import { processInstagramPost } from '@utils'
import { UiContext } from '@context'

class InstagramPost extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('stgToken'))
    if (
      this.context.stgIgUsersPost.length < 1 ||
      this.context.updateUrl === '/ap2/stg/instagram/post'
    ) {
      try {
        const posts = await getStgInstagramPosts(token)
        const data = await processInstagramPost(posts)
        this.context.setStgIgUsersPost(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.stgIgUsersPost}
        title="AP2 Staging Instagram Post"
        fileName="ap2_stg_ig_post"
      />
    )
  }
}

export default InstagramPost
