import React from 'react'
import Table from '../../../templates/errors'
import { getAP1ProdInstagramUsers } from '@utils'
import { processInstagramProfileErrors } from '@utils'
import { UiContext } from '@context'

class InstagramProfileErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.prodAP1IgUsersProfErr.length < 1 ||
      this.context.updateUrl === '/ap1/prod/instagram/profile/errors'
    ) {
      try {
        const users = await getAP1ProdInstagramUsers()
        const data = await processInstagramProfileErrors(users)
        this.context.setProdAP1IgUsersProfErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodAP1IgUsersProfErr}
        title="AP1 Production Instagram Profile Errors"
        fileName="ap1_prod_ig_prof_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramProfileErrors
