import React from 'react'
import Table from '../../../templates/postErrors'
import { getProdInstagramPosts } from '@utils'
import { processInstagramPostErrors } from '@utils'
import { UiContext } from '@context'

class InstagramPostErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('prodToken'))
    if (
      this.context.prodIgUsersPostErr.length < 1 ||
      this.context.updateUrl === '/ap2/prod/instagram/post/errors'
    ) {
      try {
        const posts = await getProdInstagramPosts(token)
        const data = processInstagramPostErrors(posts)
        this.context.setProdIgUsersPostErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodIgUsersPostErr}
        title="AP2 Production Instagram Post Errors"
        fileName="ap2_prod_ig_post_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramPostErrors
