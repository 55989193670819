import React from 'react'
import Table from '../../../templates/batch'
import { getProdInstagramUsers } from '@utils'
import { processInstagramBatch } from '@utils'
import { UiContext } from '@context'

class InstagramBatch extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('prodToken'))

    if (
      this.context.prodIgUsersBatch.length < 1 ||
      this.context.updateUrl === '/ap2/prod/instagram/batch'
    ) {
      try {
        const users = await getProdInstagramUsers(token)
        const data = await processInstagramBatch(users)
        this.context.setProdIgUsersBatch(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodIgUsersBatch}
        title="AP2 Production Instagram Batch"
        fileName="ap2_prod_ig_batch"
      />
    )
  }
}

export default InstagramBatch
