import React from 'react'
import Table from '../../../templates/errors'
import { getAP1DevInstagramUsers } from '@utils'
import { processInstagramBatchErrors } from '@utils'
import { UiContext } from '@context'

class InstagramBatchErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.devAP1IgUsersBatchErr.length < 1 ||
      this.context.updateUrl === '/ap1/dev/instagram/batch/errors'
    ) {
      try {
        const users = await getAP1DevInstagramUsers()
        const data = await processInstagramBatchErrors(users)
        this.context.setDevAP1IgUsersBatchErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.devAP1IgUsersBatchErr}
        title="AP1 Develop Instagram Batch Errors"
        fileName="ap1_prod_ig_batch_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramBatchErrors
