import React from 'react'
import Table from '../components/Table/src/'
import moment from 'moment'

class ProfileScrapeTable extends React.Component {
  state = {
    downloadFile: true,
    ageFilterChecked: false,
  }

  render() {
    const columns = [
      {
        name: 'programId',
        label: 'Program ID',
        options: {
          filter: true,
        },
      },
      {
        name: 'programName',
        label: 'Program Name',
        options: {
          filter: true,
        },
      },
      {
        name: 'igUsers',
        label: 'Total Instagram Users',
        options: {
          filter: false,
        },
      },
      {
        name: 'updateSucceeded',
        label: 'Update Succeeded (month)',
        options: {
          filter: false,
        },
      },
      {
        name: 'updateFailed',
        label: 'Update Failed (month)',
        options: {
          filter: false,
        },
      },
      {
        name: 'updateCompleted',
        label: 'Update Completed (month)',
        options: {
          filter: false,
        },
      },
      {
        name: 'updatePending',
        label: 'Update Pending (month)',
        options: {
          filter: false,
        },
      },
      {
        name: 'updatePendingAllTime',
        label: 'Update Pending (all time)',
        options: {
          filter: false,
        },
      },
      {
        name: 'successRate',
        label: '% Success Rate',
        options: {
          filter: false,
        },
      },
      {
        name: 'updateRate',
        label: '% Update Finished (month)',
        options: {
          filter: false,
        },
      },
      {
        name: 'updateAllTimeRate',
        label: '% Update Finished (all time)',
        options: {
          filter: false,
        },
      },
    ]

    let data = this.props.data

    const options = {
      filter: true,
      selectableRows: false,
      filterType: 'dropdown',
      responsive: 'stacked',
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 30, 100],
      print: false,
      textLabels: {
        body: {
          noMatch: 'プログラムが見つけられませんでした。',
          toolTip: '並び替え',
          columnHeaderTooltip: (column) => `${column.label}を並び替え`,
        },
        toolbar: {
          search: '検索',
          downloadCsv: 'CSVダウンロード',
          print: '印刷',
          viewColumns: 'コラム表示',
          filterTable: 'フィルター',
        },
        filter: {
          all: '全プログラム',
          title: 'フィルター',
          reset: 'リセット',
        },
        pagination: {
          rowsPerPage: '表示件数',
          displayRows: '/',
        },
        viewColumns: {
          title: 'カラム表示',
          titleAria: 'カラムを表示・非表示する',
        },
      },
      downloadOptions: {
        filename: `${moment().format('YYYYMMDDHHmm')}_${
          this.props.fileName
        }.csv`,
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        if (this.state.downloadFile) {
          return `${buildHead(columns)}${buildBody(data)}`.trim()
        }
        return false
      },
    }

    return (
      <React.Fragment>
        <Table
          title={this.props.title}
          data={data}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    )
  }
}

export default ProfileScrapeTable
