import React from 'react'
import Table from '../../../templates/postErrors'
import { getStgInstagramPosts } from '@utils'
import { processInstagramPostErrors } from '@utils'
import { UiContext } from '@context'

class InstagramPostErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('stgToken'))
    if (
      this.context.stgIgUsersPostErr.length < 1 ||
      this.context.updateUrl === '/ap2/stg/instagram/post/errors'
    ) {
      try {
        const posts = await getStgInstagramPosts(token)
        const data = await processInstagramPostErrors(posts)
        this.context.setStgIgUsersPostErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.stgIgUsersPostErr}
        title="AP2 Staging Instagram Post Errors"
        fileName="ap2_stg_ig_post_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramPostErrors
