import React from 'react'
import Table from '../../../templates/post'
import { getProdInstagramPosts } from '@utils'
import { processInstagramPost } from '@utils'
import { UiContext } from '@context'

class InstagramPost extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('prodToken'))

    if (
      this.context.prodIgUsersPost.length < 1 ||
      this.context.updateUrl === '/ap2/prod/instagram/post'
    ) {
      try {
        const posts = await getProdInstagramPosts(token)
        const data = processInstagramPost(posts)
        this.context.setProdIgUsersPost(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodIgUsersPost}
        title="AP2 Production Instagram Post"
        fileName="ap2_prod_ig_post"
      />
    )
  }
}

export default InstagramPost
