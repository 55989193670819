import React from 'react'
import Table from '../../../templates/profile'
import { getProdInstagramUsers } from '@utils'
import { processInstagramProfile } from '@utils'
import { UiContext } from '@context'

class InstagramProfile extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('prodToken'))
    if (
      this.context.prodIgUsersProf.length < 1 ||
      this.context.updateUrl === '/ap2/prod/instagram/profile'
    ) {
      try {
        const users = await getProdInstagramUsers(token)
        const data = await processInstagramProfile(users)
        this.context.setProdIgUsersProf(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.prodIgUsersProf}
        title="AP2 Production Instagram Profile"
        fileName="ap2_prod_ig_prof"
      />
    )
  }
}

export default InstagramProfile
