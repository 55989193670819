import React from 'react'
import Table from '../../../templates/post'
import { getAP1DevInstagramPosts } from '@utils'
import { processInstagramPost } from '@utils'
import { UiContext } from '@context'

class InstagramPost extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    if (
      this.context.devAP1IgUsersPost.length < 1 ||
      this.context.updateUrl === '/ap1/dev/instagram/post'
    ) {
      try {
        const posts = await getAP1DevInstagramPosts()
        const data = await processInstagramPost(posts)
        this.context.setDevAP1IgUsersPost(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.devAP1IgUsersPost}
        title="AP1 Develop Instagram Post"
        fileName="ap1_prod_ig_post"
      />
    )
  }
}

export default InstagramPost
