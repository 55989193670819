import React from 'react'
import Table from '../../../templates/errors'
import { getStgInstagramUsers, processInstagramBatchErrorsAp2 } from '@utils'
import { UiContext } from '@context'

class InstagramBatchErrors extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('stgToken'))
    if (
      this.context.stgIgUsersBatchErr.length < 1 ||
      this.context.updateUrl === '/ap2/stg/instagram/batch/errors'
    ) {
      try {
        const users = await getStgInstagramUsers(token)
        const data = await processInstagramBatchErrorsAp2(users)
        this.context.setStgIgUsersBatchErr(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.stgIgUsersBatchErr}
        title="AP2 Staging Instagram Batch Errors"
        fileName="ap2_stg_ig_batch_err"
        noMatch="エラーがありません。"
      />
    )
  }
}

export default InstagramBatchErrors
