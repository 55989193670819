import React from 'react'
import Table from '../../../templates/batch'
import { getStgInstagramUsers } from '@utils'
import { processInstagramBatch } from '@utils'
import { UiContext } from '@context'

class InstagramBatch extends React.Component {
  static contextType = UiContext

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('stgToken'))
    if (
      this.context.stgIgUsersBatch.length < 1 ||
      this.context.updateUrl === '/ap2/stg/instagram/batch'
    ) {
      try {
        const users = await getStgInstagramUsers(token)
        const data = await processInstagramBatch(users)
        this.context.setStgIgUsersBatch(data)
        this.context.setUpdateUrl('')
      } catch {
        this.context.setUpdateUrl('')
      }
    }
  }

  render() {
    return (
      <Table
        data={this.context.stgIgUsersBatch}
        title="AP2 Staging Instagram Batch"
        fileName="ap2_stg_ig_batch"
      />
    )
  }
}

export default InstagramBatch
